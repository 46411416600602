import React, { ReactElement, useEffect, useState } from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '@/configs/storeConfig/storeHooks';
import Button from '@/library/components/atoms/Button';
import Card from '@/library/components/atoms/Card';
import ConfirmationModal from '@/library/components/molecules/ConfirmationModal';
import Tooltip from '@/library/components/molecules/Tooltip';
import {
  endSessionReasons,
  metricNames,
} from '@/library/constants/metricConstants';
import { uiStrings } from '@/library/constants/uiStrings';
import {
  resetFilteredWORecommendationsTableList,
  resetWOEditSessionTimer,
  startWOEditSession,
  stopWOEditSession,
  updateScheduleWOMessageBanner,
} from '@/library/storeSlices/scheduleWOData/scheduleWODataActions';
import { convertMillisecondsToMinutes } from '@/library/utils/dateUtils';
import { formUiStringWithParameter } from '@/library/utils/stringUtils';
import { throttle } from '@/library/utils/throttle';
import {
  initialiseWorkOrdersData,
  recordSWOEvent,
} from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

import './editWOSessionManagerStyles.scss';

interface iEditWOSessionManagerProps {
  buttonVariant?: any;
}

const idleTimeThrottleDelay = 2000; // must be more than activeTimeThrottleDelay
const activeTimeThrottleDelay = 1000;
let interval: ReturnType<typeof setInterval>;
let timeout: ReturnType<typeof setTimeout> | null;

export function EditWOSessionManager(
  props: iEditWOSessionManagerProps,
): ReactElement {
  const { buttonVariant = 'primary' } = props;
  const [
    isEndSessionConfirmationModalVisible,
    setIsEndSessionConfirmationModalVisible,
  ] = useState(false);
  const [isIdleTimePromptModalVisible, setIsIdleTimePromptModalVisible] =
    useState(false);
  const [
    isIdleSessionTimeExceededModalVisible,
    setIsIdleSessionTimeExceededModalVisible,
  ] = useState(false);
  const [
    isEndSessionOnTimerResetFailureVisible,
    setIsEndSessionOnTimerResetFailureVisible,
  ] = useState(false);
  const [idleTimeInMilliseconds, setIdleTimeInMilliseconds] =
    useState<number>(0);

  const {
    isAnyEditMade,
    acquiringEditSessionLockInProgress,
    isEditSessionActive,
    releasingEditSessionLockInProgress,
    startActivityTrackingInActiveSession,
    woRecommendationsMasterDataWithoutUserEdits,
  } = useAppSelector(state => state.scheduleWODataReducer);

  const { userDetails, ottoAppConfig } = useAppSelector(
    state => state.masterDataReducer,
  );

  const {
    lockResetApiTimer,
    idleSessionPromptTimeout,
    lockSessionEndTimeout,
    lockSessionIdleDisclaimerTimeout,
  } = ottoAppConfig;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isEditSessionActive) {
      stopActivityTracking();
      document.removeEventListener('visibilitychange', onAppVisibilityChange);
    }
    if (isEditSessionActive) {
      if (startActivityTrackingInActiveSession) {
        onAcquireLockSuccess();
      }
      document.addEventListener('visibilitychange', onAppVisibilityChange);
    }

    return () => {
      document.removeEventListener('visibilitychange', onAppVisibilityChange);
    };
  }, [isEditSessionActive, startActivityTrackingInActiveSession]);

  const onAppVisibilityChange = (): void => {
    if (!document.hidden && isEditSessionActive) {
      resetEditSessionTimer();
    }
  };

  const startEditSession = (): void => {
    initialiseWorkOrdersData(dispatch);
    dispatch(startWOEditSession()).then(response => {
      if (
        response.payload?.status === 'Success' ||
        response.payload?.latestLockDetail.ownerEmail === userDetails?.email
      ) {
        onAcquireLockSuccess();
        recordSWOEvent(metricNames.swo_sessionStartSuccess);
      } else if (response.payload?.status === 'Failure') {
        onAcquireLockFailure(response);
        recordSWOEvent(metricNames.swo_sessionStartFailed, {
          ownerEmail: response.payload.latestLockDetail.ownerEmail,
        });
      }
    });
  };

  const onAcquireLockSuccess = (): void => {
    setIdleTimeInMilliseconds(0);
    startActivityTracking();
  };

  const startActivityTracking = (): void => {
    document.onmousemove = throttle(onActivity, activeTimeThrottleDelay);
    onActivity();
  };

  const onActivity = (): void => {
    clearInterval(interval);
    setIdleTimeInMilliseconds(0);

    if (!timeout) {
      timeout = setTimeout(() => {
        resetEditSessionTimer();
        timeout = null;
      }, lockResetApiTimer);
    }

    interval = setInterval(() => {
      onIdle();
    }, idleTimeThrottleDelay);
  };

  const resetEditSessionTimer = (): void => {
    dispatch(resetWOEditSessionTimer()).then(response => {
      const { status, failureReason } = response?.payload;
      if (status === 'Failure') {
        setIsEndSessionOnTimerResetFailureVisible(true);
        stopEditSession(
          `${endSessionReasons.somethingWentWrongOnLockReset}_${failureReason}`,
        );
      }
    });
  };

  const onIdle = (): void => {
    setIdleTimeInMilliseconds(prevCurrentIdleTime => {
      const currentIdleTime = prevCurrentIdleTime + idleTimeThrottleDelay;
      if (currentIdleTime >= idleSessionPromptTimeout) {
        !isIdleTimePromptModalVisible &&
          !isIdleSessionTimeExceededModalVisible &&
          recordSWOEvent(metricNames.swo_idleSessionPrompt, {
            currentIdleTime,
            idleSessionPromptTimeout,
          });
        setIsIdleTimePromptModalVisible(true);
      }
      if (currentIdleTime >= lockSessionEndTimeout) {
        !isIdleSessionTimeExceededModalVisible &&
          recordSWOEvent(metricNames.swo_maxIdleTimeExceeded, {
            currentIdleTime,
            lockSessionEndTimeout,
          });
        setIsIdleTimePromptModalVisible(false);
        setIsIdleSessionTimeExceededModalVisible(true);
        stopEditSession(endSessionReasons.idleSessionTimeLimitExceeded);
      }
      return currentIdleTime;
    });
  };

  const stopActivityTracking = (): void => {
    document.onmousemove = null;
    clearInterval(interval);
    timeout && clearTimeout(timeout);
    timeout = null;
  };

  const onAcquireLockFailure = (response): void => {
    dispatch(
      updateScheduleWOMessageBanner({
        type: 'error',
        header: uiStrings.sessionInProgress,
        content: formUiStringWithParameter(
          uiStrings.sessionInProgressErrorDescription,
          {
            username:
              response.payload.latestLockDetail.ownerEmail
                .split('@')[0]
                .toLowerCase() + '@',
          },
        ),
        dismissible: true,
        id: 'acquireLockFailed',
        onDismiss: () =>
          dispatch(updateScheduleWOMessageBanner('acquireLockFailed')),
      }),
    );
  };

  const stopEditSession = (endSessionMode: string): void => {
    stopActivityTracking();
    dispatch(stopWOEditSession(endSessionMode));
    dispatch(resetFilteredWORecommendationsTableList(true));
  };

  const onConfirmEndSession = (): void => {
    hideEndSessionConfirmationModal();
    stopEditSession(endSessionReasons.endSessionButtonClick);
  };

  const showEndSessionConfirmationModal = (): void => {
    if (isAnyEditMade) {
      setIsEndSessionConfirmationModalVisible(true);
    } else {
      onConfirmEndSession();
    }
  };
  const hideEndSessionConfirmationModal = (): void =>
    setIsEndSessionConfirmationModalVisible(false);

  const currentIdleTimeInMinutes = convertMillisecondsToMinutes(
    idleTimeInMilliseconds,
  );

  return (
    <>
      <ConfirmationModal
        visible={isEndSessionConfirmationModalVisible}
        onDismiss={hideEndSessionConfirmationModal}
        onConfirm={onConfirmEndSession}
        header={uiStrings.endSessionConfirmationHeading}
        confirmButtonLabel={uiStrings.endSession}
      >
        <p>{uiStrings.endSessionConfirmationDescription}</p>
      </ConfirmationModal>
      <ConfirmationModal
        visible={isIdleTimePromptModalVisible}
        onDismiss={() => setIsIdleTimePromptModalVisible(false)}
        header={uiStrings.inactiveForAWhile}
        hideConfirmButton
      >
        <p>
          {formUiStringWithParameter(uiStrings.sessionHasBeenIdle, {
            minutes: currentIdleTimeInMinutes,
          })}
        </p>
        <p>
          {formUiStringWithParameter(uiStrings.loseAccessAfterCertainDuration, {
            maximumIdleDuration: convertMillisecondsToMinutes(
              lockSessionEndTimeout,
            ),
          })}
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        visible={isIdleSessionTimeExceededModalVisible}
        onDismiss={() => setIsIdleSessionTimeExceededModalVisible(false)}
        header={uiStrings.editingSessionExpired}
        hideConfirmButton
      >
        <p>
          {formUiStringWithParameter(
            uiStrings.editingSessionExpiredDescription,
            {
              maximumIdleDuration: convertMillisecondsToMinutes(
                lockSessionEndTimeout,
              ),
            },
          )}
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        visible={isEndSessionOnTimerResetFailureVisible}
        onDismiss={() => setIsEndSessionOnTimerResetFailureVisible(false)}
        header={uiStrings.editingSessionEnded}
        hideConfirmButton
      >
        <p>{uiStrings.editingSessionEndedDueToTimerResetFailure}</p>
      </ConfirmationModal>
      {isEditSessionActive ? (
        <Card className="end-session-container">
          <div>
            <div className="session-in-progress">
              <span>{uiStrings.sessionInProgress}</span>
              <Tooltip
                triggerType="custom"
                content={
                  <p className="start-session-instructions-text">
                    {uiStrings.sessionInProgressDescription}
                  </p>
                }
                position="top"
              >
                <Button
                  iconName="status-info"
                  variant="icon"
                />
              </Tooltip>
            </div>
            {idleTimeInMilliseconds >= lockSessionIdleDisclaimerTimeout && (
              <span>
                {formUiStringWithParameter(uiStrings.sessionHasBeenIdle, {
                  minutes: currentIdleTimeInMinutes,
                  maximumIdleDuration: convertMillisecondsToMinutes(
                    lockSessionEndTimeout,
                  ),
                })}
              </span>
            )}
          </div>
          <Button
            variant="primary"
            onClick={showEndSessionConfirmationModal}
            loading={releasingEditSessionLockInProgress}
          >
            {uiStrings.endSession}
          </Button>
        </Card>
      ) : (
        <Button
          variant={buttonVariant}
          onClick={startEditSession}
          loading={acquiringEditSessionLockInProgress}
          disabled={!woRecommendationsMasterDataWithoutUserEdits.length}
        >
          {uiStrings.startSession}
        </Button>
      )}
    </>
  );
}
