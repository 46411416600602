import {
  otherServiceAPI,
  ottoRecommendationManagementServiceAPI,
  utilityServiceAPI,
} from '@/configs/apiServiceConfig/apiServiceConfig';
import { store } from '@/configs/storeConfig/storeConfig';
import { apiNames, apiRoutes } from '@/library/constants/apiConstants';
import { appConstants } from '@/library/constants/appConstants';
import { iTechnicianConfigurationRequestParams } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import {
  addNoOfDaysToDate,
  formatDate,
  getDatesInRange,
  getUTCDateByDateString,
} from '@/library/utils/dateUtils';
import { updateEndpointWithPathParams } from '@/library/utils/urlUtils';
import { formatEndorsementDataForS3Bucket } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

const getPathParams = (
  store,
  additionalParams?: Record<string, any>,
): Record<string, any> => {
  const {
    recommendationsMetadata: { modelRunDate },
  } = store.getState().scheduleWODataReducer;
  const { siteId } = store.getState().masterDataReducer.activeSite;
  return {
    site: siteId,
    modelRunDate,
    ...additionalParams,
  };
};

export const getAllShiftsForSite = (store): Promise<any> => {
  const { ottoAppConfig } = store.getState().masterDataReducer;
  const { recommendationsMetadata } = store.getState().scheduleWODataReducer;

  const modelRunDate = getUTCDateByDateString(
    recommendationsMetadata.modelRunDate,
  );

  const queryParams = {
    scheduleStartDates: getDatesInRange(
      modelRunDate,
      addNoOfDaysToDate(
        modelRunDate,
        ottoAppConfig.numberOfDatesForSchedulingWorkOrder - 1,
      ),
    ).map(date => formatDate(date, appConstants.dateFormat)),
  };

  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getAllShiftsForSite,
    getPathParams(store),
  );
  return utilityServiceAPI.get(
    { route: apiRoute, name: apiNames.getAllShiftsForSite },
    queryParams,
  );
};

export const getAllTechniciansForSite = (store): Promise<any> => {
  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getAllTechniciansForSite,
    getPathParams(store),
  );
  return utilityServiceAPI.get({
    route: apiRoute,
    name: apiNames.getAllTechniciansForSite,
  });
};

export const getRecommendationMetadataForSite = (store): Promise<any> => {
  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getRecommendationMetadataForSite,
    getPathParams(store),
  );
  return ottoRecommendationManagementServiceAPI.get({
    route: apiRoute,
    name: apiNames.getRecommendationMetadataForSite,
  });
};

export const getTechniciansConfigurationForWO = (
  store,
  queryParams: iTechnicianConfigurationRequestParams,
): Promise<any> => {
  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getTechniciansConfigurationForWO,
    getPathParams(store),
  );
  return utilityServiceAPI.get(
    { route: apiRoute, name: apiNames.getTechniciansConfigurationForWO },
    queryParams,
  );
};

export const getMisconfigurationDataForSite = (store): Promise<any> => {
  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getMisconfigurationDataForSite,
    getPathParams(store),
  );
  return utilityServiceAPI.get({
    route: apiRoute,
    name: apiNames.getMisconfigurationDataForSite,
  });
};
export const acquireEditLockForSite = (store): Promise<any> => {
  const {
    activeSite: { siteId },
    userDetails: { email },
  } = store.getState().masterDataReducer;

  return ottoRecommendationManagementServiceAPI.post(
    {
      route: apiRoutes.acquireEditLock,
      name: apiNames.acquireEditLock,
    },
    { userEmail: email, siteId },
  );
};

export const releaseEditLockForSite = (store): Promise<any> => {
  const {
    activeSite: { siteId },
    userDetails: { email },
  } = store.getState().masterDataReducer;
  return ottoRecommendationManagementServiceAPI.post(
    {
      route: apiRoutes.releaseEditLock,
      name: apiNames.releaseEditLock,
    },
    { userEmail: email, siteId },
  );
};

export const resetWOEditSessionTimerForSite = (store): Promise<any> => {
  const {
    activeSite: { siteId },
    userDetails: { email },
  } = store.getState().masterDataReducer;
  return ottoRecommendationManagementServiceAPI.post(
    {
      route: apiRoutes.resetEditLock,
      name: apiNames.resetEditLock,
    },
    { userEmail: email, siteId },
  );
};

export const endorsementDataS3Upload = (): Promise<any> => {
  const { recommendationsMetadata } = store.getState().scheduleWODataReducer;
  const endorsementTimestamp = Date.now();
  const endorsementTimestampInFileFormat = new Date(
    endorsementTimestamp,
  ).toISOString();
  const { formattedOverwriteReasonsData, formattedWOsData } =
    formatEndorsementDataForS3Bucket(endorsementTimestampInFileFormat);
  return Promise.all([
    otherServiceAPI.put(
      {
        name: apiNames.endorsementWOsDataS3Upload,
        route: recommendationsMetadata.endorsementDataPreSignedURL ?? '',
      },
      formattedWOsData,
    ),
    otherServiceAPI.put(
      {
        name: apiNames.endorsementOverwriteReasonsS3Upload,
        route: recommendationsMetadata.overrideReasonPreSignedURL ?? '',
      },
      formattedOverwriteReasonsData,
    ),
  ]).then(res => ({
    response: res,
    endorsementTimestamp,
    formattedOverwriteReasonsData,
    formattedWOsData,
  }));
};

export const endorsementSubmissionForSite = (timestamp): Promise<any> => {
  const { recommendationsMetadata } = store.getState().scheduleWODataReducer;
  const { userDetails } = store.getState().masterDataReducer;

  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.endorsementSubmission,
    getPathParams(store),
  );
  return ottoRecommendationManagementServiceAPI.post(
    {
      route: apiRoute,
      name: apiNames.endorsementSubmission,
    },
    {
      modelRunDate: recommendationsMetadata.modelRunDate,
      recommendationsFilePath: recommendationsMetadata.s3DataFileURL,
      endorsementURL: recommendationsMetadata.endorsementDataPreSignedURL,
      overrideReasonURL: recommendationsMetadata.overrideReasonPreSignedURL,
      userId: userDetails?.userId,
      userEmail: userDetails?.email,
      endorsementTimestamp: timestamp,
    },
  );
};

export const getRecommendationGenerationStatusForSite = (
  store,
): Promise<any> => {
  const apiRoute = updateEndpointWithPathParams(
    apiRoutes.getEndorsementGenerationStatus,
    getPathParams(store),
  );
  return ottoRecommendationManagementServiceAPI.get({
    route: apiRoute,
    name: apiNames.getEndorsementGenerationStatus,
  });
};
