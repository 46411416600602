import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { otherServiceAPI } from '@/configs/apiServiceConfig/apiServiceConfig';
import { getWorkOrderTypeToScreenIdMappingRequest } from '@/library/apiServices/apmServices';
import {
  acquireEditLockForSite,
  getAllShiftsForSite,
  getAllTechniciansForSite,
  getRecommendationGenerationStatusForSite,
  getRecommendationMetadataForSite,
  getTechniciansConfigurationForWO,
  releaseEditLockForSite,
  resetWOEditSessionTimerForSite,
} from '@/library/apiServices/scheduleWOServices';
import { iMessageDefinition } from '@/library/components/atoms/Banner';
import { actionTypes } from '@/library/constants/actionTypes';
import { apiNames } from '@/library/constants/apiConstants';
import { metricNames } from '@/library/constants/metricConstants';
import { iTechnicianConfigurationRequestParams } from '@/library/storeSlices/scheduleWOData/scheduleWODataTypes';
import { recordSWOEvent } from '@/pages/ScheduleWorkOrders/scheduleWorkOrdersUtils';

export const resetScheduleWOTab = createAction(actionTypes.resetScheduleWOTab);

export const startActivityTrackingInActiveSession = createAction(
  actionTypes.startActivityTrackingInActiveSession,
);

export const disableWORecommendationsLoader = createAction(
  actionTypes.disableWORecommendationsLoader,
);

export const fetchWORecommendations = createAsyncThunk(
  actionTypes.fetchWORecommendations,
  (url: string, thunkAPI: any) =>
    otherServiceAPI
      .get({
        route: url,
        name: apiNames.woRecommendationsFile,
      })
      .then(res => ({
        ...res,
        ottoAppConfig: thunkAPI.getState()?.masterDataReducer?.ottoAppConfig,
      })),
);

export const fetchWORecommendationsMetaData = createAsyncThunk(
  actionTypes.fetchWORecommendationsMetaData,
  (params, thunkAPI) => getRecommendationMetadataForSite(thunkAPI),
);

export const updateSelectedWOs = createAction(
  actionTypes.updateSelectedWOs,
  selectedWOs => {
    return {
      payload: selectedWOs,
    };
  },
);

export const editWORecommendation = createAction(
  actionTypes.editWORecommendation,
  woData => {
    return {
      payload: woData,
    };
  },
);

export const updateAppliedTableFilters = createAction(
  actionTypes.updateAppliedTableFilters,
  appliedFilters => {
    return {
      payload: appliedFilters,
    };
  },
);

export const resetFilteredWORecommendationsTableList = createAction(
  actionTypes.resetFilteredWORecommendationsTableList,
  (undoUserEdits?: boolean) => {
    return {
      payload: undoUserEdits,
    };
  },
);

export const startWOEditSession = createAsyncThunk(
  actionTypes.startWOEditSession,
  (params, thunkAPI: any) =>
    acquireEditLockForSite(thunkAPI).then(response => {
      const {
        userDetails: { email },
      } = thunkAPI.getState().masterDataReducer;
      return {
        ...response,
        currentUserId: email,
      };
    }),
);

export const stopWOEditSession = createAsyncThunk(
  actionTypes.stopWOEditSession,
  (endSessionReason: string, thunkAPI) => {
    recordSWOEvent(metricNames.swo_endSession, { endSessionReason });
    return releaseEditLockForSite(thunkAPI);
  },
);

export const resetWOEditSessionTimer = createAsyncThunk(
  actionTypes.resetWOEditSessionTimer,
  (params, thunkAPI) => resetWOEditSessionTimerForSite(thunkAPI),
);

export const fetchWorkOrderTypeToScreenIdMappingRequest = createAsyncThunk(
  actionTypes.fetchWorkOrderTypeToScreenIdMappingRequest,
  getWorkOrderTypeToScreenIdMappingRequest,
);

export const fetchAllShiftsForSite = createAsyncThunk(
  actionTypes.fetchAllShiftsForSite,
  (arg, thunkAPI) =>
    getAllShiftsForSite(thunkAPI).catch(err =>
      thunkAPI.rejectWithValue(err.response),
    ),
);

export const fetchAllTechniciansForSite = createAsyncThunk(
  actionTypes.fetchAllTechniciansForSite,
  (arg, thunkAPI) =>
    getAllTechniciansForSite(thunkAPI).catch(err =>
      thunkAPI.rejectWithValue(err.response),
    ),
);

export const fetchTechniciansConfigurationForWO = createAsyncThunk(
  actionTypes.fetchTechniciansConfigurationForWO,
  (params: iTechnicianConfigurationRequestParams, thunkAPI) =>
    getTechniciansConfigurationForWO(thunkAPI, params).catch(err =>
      thunkAPI.rejectWithValue(err.response),
    ),
);

export const fetchRecommendationGenerationStatusForSite = createAsyncThunk(
  actionTypes.fetchRecommendationGenerationStatusForSite,
  (modelRunDate: string | undefined, thunkAPI) =>
    getRecommendationGenerationStatusForSite(thunkAPI)
      .then(response => ({
        ...response,
        modelRunDate,
      }))
      .catch(err => thunkAPI.rejectWithValue(err.response)),
);

export const updateScheduleWOMessageBanner = createAction(
  actionTypes.updateScheduleWOMessageBanner,
  (payload?: iMessageDefinition | string) => ({
    payload,
  }),
);

export const sortWORecommendations = createAction(
  actionTypes.sortWORecommendations,
  payload => {
    return {
      payload,
    };
  },
);
